import React, { useState, useEffect } from "react";
import {
    Box,
    AbsoluteCenter,
    Spinner,
    Text,
    Flex,
    Stack,
    Badge,
} from "@chakra-ui/react";

export default function Home() {
    const [price, setPrice] = useState();
    const [cap, setCap] = useState();
    const [supply, setSupply] = useState();

    const url_rest = "https://api.coincap.io/v2/assets/bitcoin";
    const url_socket = "wss://ws.coincap.io/prices?assets=bitcoin";

    const getData = async () => {
        const response = await fetch(url_rest);
        const data = await response.json();
        setPrice(parseInt(data["data"]["priceUsd"]));
        setCap(parseInt(data["data"]["marketCapUsd"]));
        setSupply(parseInt(data["data"]["supply"]));
    };

    const getFeed = async () => {
        const socket = new WebSocket(url_socket);
        socket.addEventListener("message", (event) => {
            const response = JSON.parse(event.data);
            const newPrice = parseInt(response.bitcoin);
            setPrice(newPrice);
        });
    };

    useEffect(() => {
        getData();
        getFeed();
    }, []);

    return (
        <Box position="relative" height="100vh">
            <AbsoluteCenter>
                <Text className="ticker" align="center" color="orange">
                    {price?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                    })}
                </Text>
                {cap && supply && (
                    <Flex gap={12} justify="center">
                        <Stack align="center">
                            <Badge>Market Cap</Badge>
                            <Text fontWeight="bold">
                                {cap.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    maximumFractionDigits: 0,
                                })}
                            </Text>
                        </Stack>
                        <Stack align="center">
                            <Badge>Current Supply</Badge>
                            <Text fontWeight="bold">
                                {supply.toLocaleString("en-US", {
                                    maximumFractionDigits: 0,
                                })}
                            </Text>
                        </Stack>
                    </Flex>
                )}
            </AbsoluteCenter>
            <Box position="absolute" bottom="16px" left="16px" display="table">
                <Text className="jet" textColor="gray">
                    2024 © Satoshi Limited
                </Text>
            </Box>
            <Box position="absolute" bottom="16px" right="16px" display="table">
                {price ? (
                    <Flex gap={2} align="center">
                        <Text className="jet" textColor="green">
                            Connected
                        </Text>
                        <Box
                            bgColor="limegreen"
                            height="9px"
                            width="9px"
                            borderRadius="80"
                            display="table-cell"
                            verticalAlign="bottom"
                        ></Box>
                    </Flex>
                ) : (
                    <Spinner
                        size="sm"
                        color="green"
                        display="table-cell"
                        verticalAlign="bottom"
                    />
                )}
            </Box>
        </Box>
    );
}
